<template>
  <div class="ExamDetailsHeader">
    <div class="title">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <span @click="goBack">{{ name }}</span>
      <el-dropdown v-if="menuList.length > 0">
        <span class="el-dropdown-link">
          {{ indexName
          }}<i v-if="roleId != 5" class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu v-if="roleId != 5" slot="dropdown">
          <template v-for="(v, i) in menuList">
            <el-dropdown-item
              v-if="v.name != indexName"
              :key="i"
              @click.native.stop="check(v)"
            >
              {{ v.name }}
            </el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
      <template v-if="$parent.indexPaperId">
        <span> / </span>
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ $parent.indexPaperId | setName($parent.paperList) }}
            <i v-if="roleId != 5" class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu v-if="roleId != 5" slot="dropdown">
            <el-dropdown-item
              v-for="(v, i) in $parent.paperList"
              :key="i"
              @click.native.stop="$parent.checkSubject(v)"
            >
              {{ v.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    name: { type: String, default: "" },
    urlName: { type: String, default: "" },
    url: { type: String, default: "" },
    query: { type: Object, default: () => {} },
  },
  data() {
    return {
      menuList: [],
      indexName: "",
      roleId: "",
    };
  },
  created() {},
  methods: {
    check(item) {
      // console.log(item);
      let { url } = item;
      let query = {};
      let { examId, level, examType } = this.$parent.examInformation;
      let { paperId, subjectId, getType, markType } = this.indexPaper;
      if (url == "ExamTemplate") {
        query = {
          examId: examId,
          subjectId: subjectId,
        };
      } else if (url == "ExamPaperAnswer") {
        query = {
          examId: examId,
          paperId: paperId,
        };
      } else if (url == "scanSheet") {
        query = {
          examId: examId,
          examPaperId: paperId,
          level: level,
          examType: examType,
        };
      } else if (url == "ExamCorrectTask") {
        let data = {
          paperId: paperId,
          examId: examId,
          examType: examType,
        };
        if (markType == 2) {
          url = "ExamClassReadCorrectTask";
        }
        // console.log(data);
        query = data;
      } else {
        query = {
          examPaperId: paperId,
          examId: examId,
          subjectId: subjectId,
          examType: examType,
          getType: getType,
          markType: markType,
          routerName: "examlist",
          // subjectName: item.subjectName,
        };
      }
      if (examType == 1) {
        url = "union" + url;
        query.routerName = "/exam/unionExam";
      }
      this.$router.push({
        name: url,
        query: query,
      });
    },
    init(paperId) {
      let data = [];
      let ExamTemplate = { name: "模版设置", url: "ExamTemplate" };
      let ExamPaperAnswer = { name: "答案设置", url: "ExamPaperAnswer" };
      let scanSheet = { name: "扫描管理", url: "scanSheet" };
      let ExamCorrectTask = { name: "阅卷分配", url: "ExamCorrectTask" };
      let MarkingProgress = { name: "阅卷进度", url: "MarkingProgress" };
      this.indexPaper = this.$parent.examInformation.paperList.filter(
        (item) => item.paperId == paperId
      )[0];

      // console.log(this.indexPaper);
      data.push(ExamTemplate);
      if (this.indexPaper.statusSheet) {
        data.push(ExamPaperAnswer);
        data.push(scanSheet);
        if (this.indexPaper.sheetMarkType != 1) {
          data.push(ExamCorrectTask);
          data.push(MarkingProgress);
        }
      }

      const { roleId } = JSON.parse(
        localStorage.getItem("jzjx-userInfo")
      ).content;
      this.roleId = roleId;

      this.indexName = this.$route.meta.title;
      this.menuList = data;
    },
    goBack() {
      if (this.url) {
        this.$router.push({
          name: this.url,
          query: this.query,
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamDetailsHeader {
  background-color: #ffffff;
  padding: 24px 24px 0;
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  .el-button {
    margin-bottom: 24px;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #080a09;
    margin-bottom: 24px;
    cursor: pointer;
    // width: 100%;
  }
}
</style>
