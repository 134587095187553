<template>
  <div class="ExamClassReadCorrectTask">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      url="examlist"
      :query="{ examId: $route.query.examId }"
      :name="examInformation.name + ' / '"
    >
      <div>
        <el-button
          type="primary"
          plain
          :loading="markTypeLoading"
          @click="exammarkMarkType()"
          >取消班级批阅</el-button
        >
        <el-button type="primary" plain @click="showLoadMode"> 刷新 </el-button>
        <el-button type="primary" plain @click="goChoose()"
          >选做题处理</el-button
        >
        <el-button type="primary" plain @click="setScoreRules"
          >设置给分方式</el-button
        >
      </div>
    </ExamDetailsHeader>

    <div class="question-list-box">
      <div v-for="(v, i) in classNumList" :key="i" class="question-list">
        <div class="question-title">
          <div>
            <span class="class-num">
              {{ v.classNum }}
            </span>
            阅卷老师：{{ v.teacherList | getTaskTeacher() }}
          </div>

          <el-button type="text" @click="addShowList(v.classNum)"
            >按题目设置<i
              class="el-icon-arrow-down el-icon--right"
              :class="{ 'index-on': showList.indexOf(v.classNum) != -1 }"
            ></i
          ></el-button>
        </div>
        <div
          v-if="showList.indexOf(v.classNum) != -1"
          class="index-question-box"
        >
          <div class="question-title">
            <div class="question-title-left">
              <el-button
                type="text"
                @click="
                  toggleSelection(
                    2,
                    'multipleTable' + v.classNum,
                    v.questionList
                  )
                "
              >
                全选
              </el-button>
              <span class="fg">|</span>
              <el-button
                type="text"
                @click="
                  toggleSelection(
                    1,
                    'multipleTable' + v.classNum,
                    v.questionList
                  )
                "
              >
                反选
              </el-button>
              <span class="fg">|</span>
              <el-button
                type="text"
                @click="toggleSelection(0, 'multipleTable' + v.classNum)"
              >
                取消全部
              </el-button>
            </div>
            <div>
              <el-button
                type="primary"
                plain
                @click.stop="
                  showAllTeacherList(v, 'multipleTable' + v.classNum)
                "
                >批量设置</el-button
              >
              <el-button
                type="primary"
                plain
                @click="delAllTeacherList(v, 'multipleTable' + v.classNum)"
                >批量删除</el-button
              >
            </div>
          </div>

          <el-table
            :ref="'multipleTable' + v.classNum"
            :data="v.questionList"
            tooltip-effect="dark"
            style="width: 100%"
            border
            @selection-change="
              handleSelectionChange($event, 'multipleTable' + v.classNum)
            "
          >
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column
              align="center"
              label="题目"
              prop="questionNum"
              width="724"
            >
              <template slot-scope="scope">{{
                scope.row.isoption == 1
                  ? "选做题"
                  : "主观题" + scope.row.questionNum
              }}</template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="教师">
              <template slot-scope="scope">
                <!-- <template> -->
                <span
                  v-for="(m, n) in scope.row.teacherList"
                  :key="n"
                  :class="{ del_teacher: m.type == 666 }"
                >
                  {{ m.name }}
                </span>
                <!-- </template> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="阅卷教师设置"
              show-overflow-tooltip
              width="184"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="
                    showTeacherList(scope.row, 'multipleTable' + v.classNum)
                  "
                >
                  设置
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      title="批量设置教师权限"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div class="search-box">
        <div class="search-item">
          学段：
          <el-select
            v-model="modelSearch.level"
            placeholder="请选择学段"
            @change="levelChange"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <!-- yearHignOptions yearMiddleOptions -->
          <!-- index.teacherId -->
          入学年份：
          <el-select
            v-model="modelSearch.year"
            placeholder="请选择入学年份"
            clearable
            @change="gradeChange"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-select
            v-else
            v-model="modelSearch.year"
            placeholder="请选择入学年份"
            @change="gradeChange"
          >
            <el-option
              v-for="item in yearHignOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select> -->
        </div>
        <!-- <div class="search-item ts-item">
          班级：
          <el-select
            v-model="modelSearch.classNum"
            placeholder="请选择班级"
            @change="selectChange"
          >
            <el-option
              v-for="item in classOptions"
              :key="item.id"
              :label="item.classNum"
              :value="item.classNum"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="search-item">
          科目：
          <el-select
            v-model="modelSearch.subjectId"
            placeholder="请选择科目"
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          角色权限：
          <el-select
            v-model="modelSearch.roleId"
            placeholder="请选择"
            clearable
            @change="selectChange"
          >
            <el-option
              v-for="item in teacherRole"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          v-model="modelSearch.name"
          class="search-input"
          placeholder="请输入搜索内容"
          @keyup.enter.native="schoolteacherlistv2"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="schoolteacherlistv2"
          >
          </i
        ></el-input>

        <div class="search-item"></div>
      </div>
      <div class="teacher-box">
        <div>
          <div class="teacher-header">
            <el-button type="text" @click="changeChecked(1)">全选</el-button>
            <el-button type="text" @click="changeChecked(2)">反选</el-button>
            <!-- <el-checkbox v-model="checkedAll" @change="changeChecked(1)">
              全选
            </el-checkbox>
            <el-checkbox v-model="checkedFalse" @change="changeChecked(2)">
              反选
            </el-checkbox> -->
          </div>
          <div class="teacher-list-box edit-scroll-style">
            <el-checkbox-group v-model="checkTeacherList" @change="checkList">
              <el-checkbox
                v-for="(v, i) in teacherList"
                :key="i"
                :label="v.teacherId"
              >
                {{ v.name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div>
          <div class="teacher-header right-teacher-header">
            <el-button type="text" @click="clear()">清空</el-button>
          </div>
          <div class="teacher-list-box edit-scroll-style">
            <template>
              <div
                v-for="(v, i) in teacherChangeList"
                :key="i"
                class="teacher-list"
              >
                {{ v.name }}
                <i class="el-icon-close" @click="clear(v)"></i>
              </div>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="save">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  // exammarkTeacherList,
  // exammarkRuleScore,
  // exammarkSaveTeacher,
  // exammarkExportOutline,
  exammarkResetClass,
  exammarkClassList,
  exammarkMarkType,
  exammarkSaveClass,
} from "@/core/api/exam/examCorrectTask";
import { getObj } from "@/core/api/exam/exam";
import { levelOptions, teacherRole } from "@/core/util/constdata";
import ExamDetailsHeader from "./component/ExamDetailsHeader";
// import { getSubjectList } from "@/core/util/util";
import { getSubjectList, getYear } from "@/core/util/util";
import {
  getGradeList,
  getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
import {
  schoolteacherlistv2,
  // delObj,
  // exportTeacher,
} from "@/core/api/school/schoolteacher";

export default {
  name: "ExamClassReadCorrectTask",
  components: {
    ExamDetailsHeader,
  },
  filters: {
    getTaskTeacher(item) {
      let nameList = [...new Set(item.map((index) => index.name))];
      return nameList.join(" ");
    },
  },
  data() {
    return {
      paperList: [],
      yearList: getYear(),
      markTypeLoading: false,
      // 班级列表
      classNumList: [],
      // 所有试题列表
      questionList: [],
      checkAllList: [],
      indexTeacherChangeAll: "",
      //弹窗数据 start yearHignOptions yearMiddleOptions
      dialogVisible: false,
      checkedAll: false,
      btnLoading: false,
      checkedFalse: false,
      checkTeacherList: [],
      teacherList: [],
      // 选中的教师列表
      teacherChangeList: [],
      yearMiddleOptions: [],
      yearHignOptions: [],
      classOptions: [],
      subjectOptions: [],

      levelOptions: levelOptions(),
      teacherRole: teacherRole,
      modelSearch: {
        level: "",
        subjectId: "",
        year: "",
        roleId: "",
        name: "",
        classNum: "",
      },
      examInformation: { name: "" },
      // 弹窗数据 end,
      resetTeacherLoading: false,
      showList: [],
      indexPaperId: "",
    };
  },
  watch: {
    checkTeacherList() {
      // 第二次判断
      this.teacherChangeList.map((index) => {
        this.teacherList.map((item) => {
          if (index.teacherId == item.teacherId) {
            if (this.checkTeacherList.indexOf(index.teacherId) == -1) {
              this.teacherChangeList = this.teacherChangeList.filter(
                (i) => i.teacherId != index.teacherId
              );
            }
          }
        });
      });
    },
  },
  created() {
    this.examInformationPaper = this.$route.query;
    this.modelSearch.examPaperId = this.examInformationPaper.paperId;

    this.subjectOptions = getSubjectList();
    this.getObj();

    this.schoolteacherlistv2();
    this.getSchoolYearList();
  },
  methods: {
    //获取考试基本信息
    getObj() {
      getObj(this.examInformationPaper.examId).then((res) => {
        //获取当前年级班级

        this.indexPaperId = this.examInformationPaper.paperId;
        this.paperList = res.data.data.paperList.map((item) => {
          if (this.indexPaperId == item.paperId) {
            if (item.markType == 2) {
              this.exammarkClassList();
            } else {
              this.$router.push({
                name: "ExamCorrectTask",
                query: this.examInformationPaper,
              });
            }
          }
          if (item.statusSheet == 1 && item.sheetMarkType != 1) {
            item.label = item.subjectName;
            item.value = item.paperId;
            return item;
          } else {
            return null;
          }
        });
        this.paperList = this.paperList.filter((item) => item);

        this.examInformation = res.data.data;

        this.$nextTick(() => {
          this.$refs.ExamDetailsHeader.init(this.indexPaperId);
        });
      });
    },
    checkList() {
      this.teacherList.forEach((item) => {
        this.checkTeacherList.forEach((index) => {
          if (item.teacherId == index) {
            if (
              this.teacherChangeList.filter((i) => i.teacherId == index)
                .length == 0
            ) {
              this.teacherChangeList.push({
                name: item.name,
                teacherId: item.teacherId,
              });
            }
          }
        });
      });
    },
    gradeChange() {
      this.schoolteacherlistv2();
    },
    checkSubject(v) {
      this.examInformationPaper.paperId = v.paperId;
      // this.examInformationPaper.subjectName = v.subjectName;
      // this.examInformationPaper.getType = v.getType;
      // this.examInformationPaper.markType = v.markType;
      // this.examInformationPaper.level = this.examInformation.level;
      // this.examInformationPaper.year = this.examInformation.year;
      // this.examInformationPaper.subjectId = v.subjectId;
      // this.examInformationPaper.type = v.type;

      this.indexPaperId = v.paperId;

      this.$nextTick(() => {
        if (!this.$refs.ExamDetailsHeader) return;
        this.$refs.ExamDetailsHeader.init(this.indexPaperId);
      });
      if (this.examInformationPaper.markType == 2) {
        this.$router.push({
          query: this.examInformationPaper,
        });
        return;
      }
      this.$router.push({
        name: "ExamCorrectTask",
        query: this.examInformationPaper,
      });
    },
    goBack() {
      this.$router.push({
        name: "examlist",
        query: { examId: this.examInformationPaper.examId },
        // :url="'examlist'"
      });
    },
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level === 2) {
              this.yearMiddleOptions.push(item.year);
            } else {
              this.yearHignOptions.push(item.year);
            }
          });
        }
        this.yearHignOptions = [...new Set(this.yearHignOptions)];
        this.yearMiddleOptions = [...new Set(this.yearMiddleOptions)];
      });
    },
    // 获取班级列表
    changeClassOptions() {
      if (this.modelSearch.level && this.modelSearch.year) {
        let query = {
          level: this.modelSearch.level,
          year: this.modelSearch.year,
        };
        getClassListByLevelAndYear(query).then((response) => {
          this.classOptions = response.data.data;
          this.modelSearch.classNum = "";
          if (this.classOptions.length > 0) {
            this.schoolteacherlistv2();
          }
        });
      }
    },
    // 反选   取消
    toggleSelection(type, key, list) {
      if (type) {
        if (type == 1) {
          if (!this[this.indexTeacherChangeAll]) {
            this.$refs[key][0].clearSelection();
            list.forEach((row) => {
              this.$refs[key][0].toggleRowSelection(row);
            });
          } else {
            let array = this[this.indexTeacherChangeAll].map((item) => item.id);
            this.$refs[key][0].clearSelection();
            list.forEach((row) => {
              // console.log(array.indexOf(row.id) == -1);
              if (array.indexOf(row.id) == -1) {
                this.$refs[key][0].toggleRowSelection(row);
              }
            });
          }
        } else {
          this.$refs[key][0].clearSelection();
          list.forEach((row) => {
            this.$refs[key][0].toggleRowSelection(row);
          });
        }

        // console.log(list);
        // console.log(this[this.indexTeacherChangeAll]);
      } else {
        this.$refs[key][0].clearSelection();
      }
    },
    setScoreRules() {
      this.examInformationPaper.type = 2;
      this.examInformationPaper.fromUrl = this.$route.name;
      this.$router.push({
        name: "ExamSetTaskRules",
        query: this.examInformationPaper,
      });
    },
    // 添加 删除显示Tab列表
    addShowList(classNum) {
      if (this.showList.indexOf(classNum) == -1) {
        this.showList.push(classNum);
      } else {
        this.showList = this.showList.filter((i) => i != classNum);
      }
    },
    showLoadMode() {
      const h = this.$createElement;
      this.$msgbox({
        title: "温馨提示",
        message: h("p", null, [
          h(
            "span",
            null,
            "刷新将重新设置阅卷教师，默认为任课教师，是否重新设置?"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.exammarkResetClass(instance, done);
            // setTimeout(() => {
            //   done();
            //   setTimeout(() => {
            //     instance.confirmButtonLoading = false;
            //   }, 300);
            // }, 3000);
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    // 选做题页面
    goChoose() {
      this.$router.push({
        name: "ExamChooseQuestion",
        query: this.examInformationPaper,
      });
    },
    // 重置阅卷老师
    exammarkResetClass(instance, done) {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      // this.resetTeacherLoading = true;
      exammarkResetClass(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "刷新成功，阅卷老师将设置为任课教师!",
          });
          done();
          this.resetTeacherLoading = false;
          instance.confirmButtonLoading = false;
          this.exammarkClassList();
        })
        .catch(() => {
          this.resetTeacherLoading = false;
          instance.confirmButtonText = "确定";
          instance.confirmButtonLoading = false;
        });
    },
    // 教师弹窗 start
    // 显示教师弹窗
    showTeacherList(list, type) {
      this.dialogVisible = true;
      this.indexTeacherChangeAll = type;
      this.indexType = 1;
      // this[type] = [list];
      this.checkTeacherList = [];
      this.teacherChangeList = [];
      this.checkTeacherList = list.teacherList.map((item) => item.id);
      this.teacherChangeList = [...list.teacherList];
      this.teacherChangeList.map((item) => {
        item.teacherId = item.id;
      });
      this.oneClassList = [list];
      // console.log(this.oneClassList);
    },
    // 批量时显示教师弹窗
    showAllTeacherList(v, type) {
      this.indexType = 2;
      if (!this[type] || (this[type] && this[type].length == 0)) {
        this.$message({
          message: `请先选择${v.classNum}班需要设置的题目`,
          type: "warning",
        });
        return;
      }

      this.checkTeacherList = [];
      let arr = [];
      this[type].map((item) => {
        if (!item.teacherIds) return;

        item.teacherList.map((index) => {
          if (this.checkTeacherList.indexOf(index.id) == -1) {
            index.teacherId = index.id;
            arr.push(index);
          }
        });
        this.checkTeacherList = this.checkTeacherList.concat(
          item.teacherIds.split(",").map((item) => Number(item))
        );
      });

      this.checkTeacherList = [...new Set(this.checkTeacherList)];

      this.teacherChangeList = arr;

      this.dialogVisible = true;
      this.indexTeacherChangeAll = type;
    },
    // 批量删除教师弹窗
    delAllTeacherList(v, type) {
      if (!this[type] || (this[type] && this[type].length == 0)) {
        this.$message({
          message: `请先选择${v.classNum}班需要删除老师的题目`,
          type: "warning",
        });
        return;
      }
      let QuestionArr = [];
      this[this.indexTeacherChangeAll].forEach((item) => {
        QuestionArr.push(item.questionNum);
      });
      this.$confirm(
        `确定删除${v.classNum}班.第${QuestionArr.join(",")}题的阅卷老师？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let data = {
            examPaperId: this.examInformationPaper.paperId,
            classNum: this[this.indexTeacherChangeAll][0].classNum,
            teacherIds: "",
            questionNum: QuestionArr.join(","),
          };
          this.exammarkSaveClass(data);
        })
        .catch(() => {});
    },
    // 删除老师
    clear(v) {
      // console.log(v);
      if (!v) {
        this.checkTeacherList = [];
        this.teacherChangeList = [];
      } else {
        this.checkTeacherList = this.checkTeacherList.filter(
          (item) => item != v.teacherId
        );
        this.teacherChangeList = this.teacherChangeList.filter(
          (item) => item.teacherId != v.teacherId
        );
      }
    },
    // 保存
    save() {
      let questionNumArr = [];

      if (this.indexType == 1) {
        this.oneClassList.forEach((item) => {
          questionNumArr.push(item.questionNum);
        });
      } else {
        this[this.indexTeacherChangeAll].forEach((item) => {
          questionNumArr.push(item.questionNum);
        });
      }

      let data = {
        examPaperId: this.examInformationPaper.paperId,
        classNum:
          this.indexType == 1
            ? this.oneClassList[0].classNum
            : this[this.indexTeacherChangeAll][0].classNum,
        teacherIds: this.checkTeacherList.join(","),
        questionNum: questionNumArr.join(","),
      };
      // console.log(data);
      this.btnLoading = true;
      this.exammarkSaveClass(data);
    },
    exammarkSaveClass(data) {
      exammarkSaveClass(data)
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$message({
            type: "success",
            message: "设置成功!",
          });
          this.exammarkClassList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    selectChange() {
      this.schoolteacherlistv2();
    },
    // 修改学段
    levelChange() {
      this.subjectOptions = getSubjectList(this.modelSearch.level);
      let filterList = this.subjectOptions.filter(
        (item) => this.modelSearch.subjectId == item.subjectId
      );
      if (filterList.length == 0) {
        this.modelSearch.subjectId = "";
      }
      this.schoolteacherlistv2();
    },
    //获取老师信息
    schoolteacherlistv2() {
      schoolteacherlistv2(this.modelSearch).then((response) => {
        this.teacherList = response.data.data;
      });
    },
    //全选 反选
    changeChecked(type) {
      // type 等于1就是全选  2就是反选
      if (type == 1) {
        this.checkedFalse = false;
        this.checkTeacherList = [];
        this.teacherChangeList = [];
        // if (this.checkedAll) {
        this.teacherList.forEach((index) => {
          this.checkTeacherList.push(index.teacherId);
          this.teacherChangeList.push({
            name: index.name,
            teacherId: index.teacherId,
          });
        });
        // }
        // this.teacherChangeList.push({
        //         name: item.name,
        //         teacherId: item.teacherId,
        //       });
      } else {
        this.checkedAll = false;
        let array = [];
        this.teacherChangeList = [];
        this.teacherList.forEach((index) => {
          if (
            this.checkTeacherList.filter((item) => item == index.teacherId)
              .length == 0
          )
            array.push(index.teacherId);
          this.teacherChangeList.push({
            name: index.name,
            teacherId: index.teacherId,
          });
        });
        this.checkTeacherList = array;
      }
      // console.log(this.checkTeacherList);
    },
    // 教师弹窗 end
    // 全选  反选
    handleSelectionChange(val, type) {
      // console.log("触发了");
      this.indexTeacherChangeAll = type;
      this[this.indexTeacherChangeAll] = JSON.parse(JSON.stringify(val));
      // console.log(this[this.indexTeacherChangeAll]);
    },
    exammarkClassList() {
      let data = {
        examPaperId: this.examInformationPaper.paperId,
      };
      exammarkClassList(data).then((res) => {
        this.classNumList = [];
        this.questionList = res.data.data;
        this.setListData();
      });
    },
    // 根据已有数据剔出需要渲染的一级列表数据
    setListData() {
      let arrTrue = [];
      this.questionList.forEach((item, key) => {
        item.id = key;
        if (arrTrue.indexOf(item.classNum) == -1) {
          arrTrue.push(item.classNum);
          this.classNumList.push({
            classNum: item.classNum,
            teacherList: [],
          });
          this.questionList.forEach((index) => {
            if (item.classNum == index.classNum) {
              index.teacherList.forEach((indexTeacher) => {
                if (
                  this.classNumList[
                    this.classNumList.length - 1
                  ].teacherList.indexOf(indexTeacher) == -1
                ) {
                  this.classNumList[
                    this.classNumList.length - 1
                  ].teacherList.push(indexTeacher);
                }
              });
            }
          });
        }
      });
      // console.log(this.classNumList);
      this.classNumList.map((item) => {
        item.questionList = [];
        this.questionList.forEach((index) => {
          if (item.classNum == index.classNum) {
            item.questionList.push(index);
          }
        });
      });
      this.classNumList = JSON.parse(JSON.stringify(this.classNumList));
      // this.showList.push(this.classNumList[0].classNum);
    },
    // 切换考试分工类型
    exammarkMarkType() {
      let data = { examPaperId: this.examInformationPaper.paperId, type: 1 };
      this.markTypeLoading = true;
      exammarkMarkType(data)
        .then(() => {
          this.markTypeLoading = false;
          this.$message({
            type: "success",
            message: "切换成功!",
          });
          let data = Object.assign({}, this.examInformationPaper);

          this.$router.push({
            name: "ExamCorrectTask",
            query: data,
          });
        })
        .catch(() => {
          this.markTypeLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamClassReadCorrectTask {
  padding: 24px 0 60px;
  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }
  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .el-select {
      width: 112px;
      height: 32px;
    }
    .el-input {
      width: 192px;
    }
    .header-search-box {
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;

      .search-item {
        font-size: 14px;
        margin-right: 24px;
        margin-bottom: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .btn-list {
      margin-bottom: 24px;
    }
    .title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
  .del_teacher {
    color: #dddddd;
  }
  ::v-deep th {
    .el-checkbox {
      // opacity: 0;
      display: none;
    }
  }
  .teacher-box {
    display: flex;
    .teacher-list-box {
      padding: 18px 24px;
      height: 338px;
      overflow-y: scroll;
      .teacher-list {
        color: $primary-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        i {
          cursor: pointer;
          font-size: 16px;
          color: #dee1e7;
        }
      }
      .el-checkbox {
        display: block;
        margin-bottom: 18px;
      }
    }
    .right-teacher-header {
      display: flex;
      justify-content: flex-end;
    }
    .teacher-header {
      padding: 9px 24px;
      background: #f5f7fa;
      width: 350px;
      .el-checkbox {
        margin-bottom: 0;
      }
    }
    > div {
      border: 1px solid #dee1e7;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
  .search-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .search-item.ts-item {
      justify-content: flex-end;
    }
    .search-input {
      width: 250px;
    }
    .search-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;
      width: 250.5px;
      .el-select {
        width: 166px;
        // margin-left: 8px;
        // margin-right: 24px;
      }
    }
  }
  .question-list-box {
    margin-top: 24px;
    background-color: #ffffff;
    .question-list {
      box-shadow: 0px 0px 5px 0px rgba(231, 231, 231, 0.7);
      padding: 24px;
      .index-question-box {
        .question-title {
          padding: 24px 0;
          .question-title-left {
            display: flex;
            align-items: center;
            // width: 120px;
            // justify-content: space-between;
            .fg {
              color: #d8d8d8;
              margin: 0 12px;
              font-size: 12px;
            }
          }
        }
      }
      .question-title {
        display: flex;
        justify-content: space-between;
        i {
          transition: 0.3s;
        }
        .index-on {
          transition: 0.3s;
          transform: rotate(180deg);
          -ms-transform: rotate(180deg); /* IE 9 */
          -moz-transform: rotate(180deg); /* Firefox */
          -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
          -o-transform: rotate(180deg); /* Opera */
        }
        .class-num {
          padding: 4px 14px;
          background-color: #ecf3fe;
          color: #2474ed;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
